export default [
  {
   nannyID: "4218",
   location: "Boulder, CO",
   age: "25",
   experience: "Nanny, Babysitter, Camp Counselor! Current My Boulder Nanny full time for one 3 year old girl, prior Temp nanny for My Boulder Nanny for 22 month old and 5 month old brothers! I have been a career nanny for the past 3 years now! I'm looking for my next family beginning at the end of August!",
   vehicle: "Yes",
   payRate: "$28/hour+",
   availability: "M-F, 8:30AM-4:30PM, August 15th"
  },
  {
   nannyID: "8136",
   location: "Westminster, CO",
   age: "22",
   experience: "Nanny, Babysitter, Early Childhood Major - My Boulder Nanny 45 hours per week for a 2 and 4 year old in Boulder, past My Boulder Nanny afterschool for 1 year for a 4.5 year old girl, prior experience as a nanny and babysitter! I'm looking for my next full time or overtime family beginning at the end of August 2024! ",
   vehicle: "Yes",
   payRate: "$28/hour+",
   availability: "35+ Hours/Week, M-F"
  },
  {
   nannyID: "6675",
   location: "Loveland, CO",
   age: "56",
   experience: "Current Overnight Newborn Nanny @ My Boulder Nanny, Doula, Lactation Consultation, Overnight Care, Part Time Nanny, Mom to adult children",
   vehicle: "Yes",
   payRate: "$28/hr+",
   availability: "Mid July 2024, Part or Full Time during the weekday"
 },
 {
   nannyID: "5335",
   location: "Franktown, CO",
   age: "21",
   experience: "I am a division 1 swimmer who has nannied kids for the past 5 years. I love to share my knowledge of not only the water, but education, life and social skills, and much more. It is my passion to take care of kids and help them grow into wonderful young adults.",
   vehicle: "Yes",
   payRate: "$20/hr+",
   availability: " May 8th start date, I am free to work all summer everyday all hours of the day"
 },
 {
   nannyID: "9341",
   location: "Boulder, CO",
   age: "20",
   experience: "I love working with children and being a part of their life growing up. I've always felt rewarded when I get to watch children's milestones through growing up as I babysit or nanny them. I have future goals to become a Psychologist! I am currently working towards my degree in that and would love to be a child therapy one day.",
   vehicle: "Yes",
   payRate: "$25/hr +",
   availability: " May 16th onward, open availability all summer"
 },
 {
   nannyID: "7738",
   location: "Erie, CO",
   age: "22",
   experience: "I have been working with children since I was 15 years old and have grown to love it more and more each year. I truly believe childcare is my passion and where I want to be for a long, long time. I am motivated and energetic which shows to the families and kids making them excited to tackle the day with me! I am loving and nurturing which helps kids open up to me and truly feel comfortable around me. Overall, I love trying new things with kids, having fun, working hard and making everyone's lives easier!",
   vehicle: "Yes",
   payRate: "$30+/hour",
   availability: "Full Time 35-50 hours per week",
   interests: "Please inquire if interested"
 }
];
